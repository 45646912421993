import fetch from "isomorphic-fetch"
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from "@apollo/client"
import { parseCookies } from 'nookies'


const cookies = parseCookies()
const tokenAuth = cookies.authToken


const wpClient = new HttpLink({
  uri: process.env.GATSBY_WORDPRESS_URL,
  fetch,
  fetchOptions: {
    "Access-Control-Allow-Origin": "*",
  },
})

const streamworxClient = new HttpLink({
  uri: process.env.GATSBY_APP_URL,
  fetch,
  headers: {
    authorization: tokenAuth ? `Bearer ${tokenAuth}` : "",
  },
})

export const client = new ApolloClient({
  link: ApolloLink.split(
    operation => operation.getContext().clientName === "streamworx",
    streamworxClient,
    wpClient
  ),
  cache: new InMemoryCache(),
})
