// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bantuan-js": () => import("./../../../src/pages/bantuan.js" /* webpackChunkName: "component---src-pages-bantuan-js" */),
  "component---src-pages-bpjskesehatan-index-js": () => import("./../../../src/pages/bpjskesehatan/index.js" /* webpackChunkName: "component---src-pages-bpjskesehatan-index-js" */),
  "component---src-pages-bpjskesehatan-payment-confirmation-js": () => import("./../../../src/pages/bpjskesehatan/payment/confirmation.js" /* webpackChunkName: "component---src-pages-bpjskesehatan-payment-confirmation-js" */),
  "component---src-pages-bpjskesehatan-payment-index-js": () => import("./../../../src/pages/bpjskesehatan/payment/index.js" /* webpackChunkName: "component---src-pages-bpjskesehatan-payment-index-js" */),
  "component---src-pages-bpjskesehatan-payment-method-js": () => import("./../../../src/pages/bpjskesehatan/payment/method.js" /* webpackChunkName: "component---src-pages-bpjskesehatan-payment-method-js" */),
  "component---src-pages-bpjskesehatan-register-js": () => import("./../../../src/pages/bpjskesehatan/register.js" /* webpackChunkName: "component---src-pages-bpjskesehatan-register-js" */),
  "component---src-pages-bpjskesehatan-riwayat-js": () => import("./../../../src/pages/bpjskesehatan/riwayat.js" /* webpackChunkName: "component---src-pages-bpjskesehatan-riwayat-js" */),
  "component---src-pages-covid-19-test-index-js": () => import("./../../../src/pages/covid-19-test/index.js" /* webpackChunkName: "component---src-pages-covid-19-test-index-js" */),
  "component---src-pages-covid-19-test-riwayat-js": () => import("./../../../src/pages/covid-19-test/riwayat.js" /* webpackChunkName: "component---src-pages-covid-19-test-riwayat-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontak-kami-en-js": () => import("./../../../src/pages/kontak-kami.en.js" /* webpackChunkName: "component---src-pages-kontak-kami-en-js" */),
  "component---src-pages-kontak-kami-js": () => import("./../../../src/pages/kontak-kami.js" /* webpackChunkName: "component---src-pages-kontak-kami-js" */),
  "component---src-pages-login-index-js": () => import("./../../../src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-pages-login-verify-js": () => import("./../../../src/pages/login/verify.js" /* webpackChunkName: "component---src-pages-login-verify-js" */),
  "component---src-pages-mammogram-form-js": () => import("./../../../src/pages/mammogram/form.js" /* webpackChunkName: "component---src-pages-mammogram-form-js" */),
  "component---src-pages-mammogram-index-js": () => import("./../../../src/pages/mammogram/index.js" /* webpackChunkName: "component---src-pages-mammogram-index-js" */),
  "component---src-pages-membership-en-js": () => import("./../../../src/pages/membership.en.js" /* webpackChunkName: "component---src-pages-membership-en-js" */),
  "component---src-pages-membership-js": () => import("./../../../src/pages/membership.js" /* webpackChunkName: "component---src-pages-membership-js" */),
  "component---src-pages-program-en-js": () => import("./../../../src/pages/program.en.js" /* webpackChunkName: "component---src-pages-program-en-js" */),
  "component---src-pages-program-js": () => import("./../../../src/pages/program.js" /* webpackChunkName: "component---src-pages-program-js" */),
  "component---src-pages-register-create-pin-js": () => import("./../../../src/pages/register/create-pin.js" /* webpackChunkName: "component---src-pages-register-create-pin-js" */),
  "component---src-pages-register-index-js": () => import("./../../../src/pages/register/index.js" /* webpackChunkName: "component---src-pages-register-index-js" */),
  "component---src-pages-register-pakdok-js": () => import("./../../../src/pages/register/pakdok.js" /* webpackChunkName: "component---src-pages-register-pakdok-js" */),
  "component---src-pages-registration-reward-en-js": () => import("./../../../src/pages/registration-reward.en.js" /* webpackChunkName: "component---src-pages-registration-reward-en-js" */),
  "component---src-pages-registration-reward-js": () => import("./../../../src/pages/registration-reward.js" /* webpackChunkName: "component---src-pages-registration-reward-js" */),
  "component---src-pages-save-ibuku-form-js": () => import("./../../../src/pages/save-ibuku/form.js" /* webpackChunkName: "component---src-pages-save-ibuku-form-js" */),
  "component---src-pages-save-ibuku-index-en-js": () => import("./../../../src/pages/save-ibuku/index.en.js" /* webpackChunkName: "component---src-pages-save-ibuku-index-en-js" */),
  "component---src-pages-save-ibuku-index-js": () => import("./../../../src/pages/save-ibuku/index.js" /* webpackChunkName: "component---src-pages-save-ibuku-index-js" */),
  "component---src-pages-tentang-kami-en-js": () => import("./../../../src/pages/tentang-kami.en.js" /* webpackChunkName: "component---src-pages-tentang-kami-en-js" */),
  "component---src-pages-tentang-kami-js": () => import("./../../../src/pages/tentang-kami.js" /* webpackChunkName: "component---src-pages-tentang-kami-js" */),
  "component---src-pages-work-from-bali-en-js": () => import("./../../../src/pages/work-from-bali.en.js" /* webpackChunkName: "component---src-pages-work-from-bali-en-js" */),
  "component---src-pages-work-from-bali-js": () => import("./../../../src/pages/work-from-bali.js" /* webpackChunkName: "component---src-pages-work-from-bali-js" */),
  "component---src-templates-aman-update-js": () => import("./../../../src/templates/aman-update.js" /* webpackChunkName: "component---src-templates-aman-update-js" */),
  "component---src-templates-bantuan-detail-js": () => import("./../../../src/templates/bantuan-detail.js" /* webpackChunkName: "component---src-templates-bantuan-detail-js" */),
  "component---src-templates-bantuan-list-js": () => import("./../../../src/templates/bantuan-list.js" /* webpackChunkName: "component---src-templates-bantuan-list-js" */),
  "component---src-templates-posts-js": () => import("./../../../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */),
  "component---src-templates-services-detail-js": () => import("./../../../src/templates/services-detail.js" /* webpackChunkName: "component---src-templates-services-detail-js" */),
  "component---src-templates-work-from-bali-details-js": () => import("./../../../src/templates/work-from-bali-details.js" /* webpackChunkName: "component---src-templates-work-from-bali-details-js" */)
}

