import React from "react"
import { ApolloProvider } from "@apollo/client"

import { client } from "./src/apollo/client"
import { LoadingProvider } from "./src/context/loading-context"

import "./src/assets/scss/main.scss"
import "react-datepicker/dist/react-datepicker.css"

export const wrapRootElement = ({ element }) => (
  <LoadingProvider>
    <ApolloProvider client={client}>{element}</ApolloProvider>
  </LoadingProvider>
)
